<div class="site white-section">
    <div class="re-top-space-holder black-section">
        <h1>Datenschutzerkl&auml;rung</h1>
    </div>
    <div class="container width-limit privacy-content">
            <h2>1) Information &uuml;ber die Erhebung personenbezogener Daten und Kontaktdaten des Verantwortlichen</h2>
            
            <p><b>1.1</b>&thinsp;Wir freuen uns, dass Sie unsere Website besuchen und bedanken uns f&uuml;r Ihr Interesse. Im Folgenden informieren wir Sie &uuml;ber den Umgang mit Ihren personenbezogenen Daten bei Nutzung unserer Website. Personenbezogene Daten sind hierbei alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.</p>
            
            <p><b>1.2</b>&thinsp;Verantwortlicher f&uuml;r die Datenverarbeitung auf dieser Website im Sinne der Datenschutz-Grundverordnung (DSGVO) ist Reedu GmbH &amp; Co. KG, Von-Steuben-Str. 21, 48143 M&uuml;nster, Deutschland, Tel.: +49 (0) 251 98119797, E-Mail: kontakt@reedu.de. Der f&uuml;r die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet.</p>
            
            <p><b>1.3</b>&thinsp;Diese Website nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung personenbezogene Daten und anderer vertraulicher Inhalte (z.B. Bestellungen oder Anfragen an den Verantwortlichen) eine SSL-bzw. TLS-Verschl&uuml;sselung. Sie k&ouml;nnen eine verschl&uuml;sselte Verbindung an der Zeichenfolge &bdquo;https://&ldquo; und dem Schloss-Symbol in Ihrer Browserzeile erkennen.</p>
            
            <h2>2) Datenerfassung beim Besuch unserer Website</h2>
            
            <p>Bei der blo&szlig; informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen &uuml;bermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server &uuml;bermittelt (sog. &bdquo;Server-Logfiles&ldquo;). Wenn Sie unsere Website aufrufen, erheben wir die folgenden Daten, die f&uuml;r uns technisch erforderlich sind, um Ihnen die Website anzuzeigen:</p>
            
            <ul>
            <li>Unsere besuchte Website</li>
            <li>Datum und Uhrzeit zum Zeitpunkt des Zugriffes</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendete IP-Adresse (ggf.: in anonymisierter Form)</li>
            </ul>
            
            <p>Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer Website. Eine Weitergabe oder anderweitige Verwendung der Daten findet nicht statt. Wir behalten uns allerdings vor, die Server-Logfiles nachtr&auml;glich zu &uuml;berpr&uuml;fen, sollten konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hinweisen.</p>
            
            <h2>3) Cookies</h2>
            
            <p>Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu erm&ouml;glichen, verwenden wir auf verschiedenen Seiten sogenannte Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem Endger&auml;t abgelegt werden. Einige der von uns verwendeten Cookies werden nach dem Ende der Browser-Sitzung, also nach Schlie&szlig;en Ihres Browsers, wieder gel&ouml;scht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endger&auml;t und erm&ouml;glichen uns oder unseren Partnerunternehmen (Cookies von Drittanbietern), Ihren Browser beim n&auml;chsten Besuch wiederzuerkennen (persistente Cookies). Werden Cookies gesetzt, erheben und verarbeiten diese im individuellen Umfang bestimmte Nutzerinformationen wie Browser- und Standortdaten sowie IP-Adresswerte. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gel&ouml;scht, die sich je nach Cookie unterscheiden kann.</p>
            
            <p>Teilweise dienen die Cookies dazu, durch Speicherung von Einstellungen den Bestellprozess zu vereinfachen (z.B. Merken des Inhalts eines virtuellen Warenkorbs f&uuml;r einen sp&auml;teren Besuch auf der Website). Sofern durch einzelne von uns implementierte Cookies auch personenbezogene Daten verarbeitet werden, erfolgt die Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO entweder zur Durchf&uuml;hrung des Vertrages oder gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der bestm&ouml;glichen Funktionalit&auml;t der Website sowie einer kundenfreundlichen und effektiven Ausgestaltung des Seitenbesuchs.</p>
            
            <p>Wir arbeiten unter Umst&auml;nden mit Werbepartnern zusammen, die uns helfen, unser Internetangebot f&uuml;r Sie interessanter zu gestalten. Zu diesem Zweck werden f&uuml;r diesen Fall bei Ihrem Besuch unserer Website auch Cookies von Partnerunternehmen auf Ihrer Festplatte gespeichert (Cookies von Drittanbietern). Wenn wir mit vorbenannten Werbepartnern zusammenarbeiten, werden Sie &uuml;ber den Einsatz derartiger Cookies und den Umfang der jeweils erhobenen Informationen innerhalb der nachstehenden Abs&auml;tze individuell und gesondert informiert.</p>
            
            <p>Bitte beachten Sie, dass Sie Ihren Browser so einstellen k&ouml;nnen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und einzeln &uuml;ber deren Annahme entscheiden oder die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en k&ouml;nnen. Jeder Browser unterscheidet sich in der Art, wie er die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemen&uuml; jedes Browsers beschrieben, welches Ihnen erl&auml;utert, wie Sie Ihre Cookie-Einstellungen &auml;ndern k&ouml;nnen. Diese finden Sie f&uuml;r die jeweiligen Browser unter den folgenden Links:</p>
            
            <p>Internet Explorer: http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies<br />
            Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen<br />
            Chrome: http://support.google.com/chrome/bin/answer.py?hl=de&amp;hlrm=en&amp;answer=95647<br />
            Safari: https://support.apple.com/kb/ph21411?locale=de_DE<br />
            Opera: http://help.opera.com/Windows/10.20/de/cookies.html</p>
            
            <p>Bitte beachten Sie, dass bei Nichtannahme von Cookies die Funktionalit&auml;t unserer Website eingeschr&auml;nkt sein kann.</p>
            
            <h2>4) Kontaktaufnahme</h2>
            
            <p>Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschlie&szlig;lich zum Zweck der Beantwortung Ihres Anliegens bzw. f&uuml;r die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage f&uuml;r die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines Vertrages ab, so ist zus&auml;tzliche Rechtsgrundlage f&uuml;r die Verarbeitung Art. 6 Abs. 1 lit. b DSGVO. Ihre Daten werden nach abschlie&szlig;ender Bearbeitung Ihrer Anfrage gel&ouml;scht, dies ist der Fall, wenn sich aus den Umst&auml;nden entnehmen l&auml;sst, dass der betroffene Sachverhalt abschlie&szlig;end gekl&auml;rt ist und sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>
            
            <h2>5) Datenverarbeitung bei Er&ouml;ffnung eines Kundenkontos und zur Vertragsabwicklung</h2>
            
            <p>Gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO werden personenbezogene Daten weiterhin erhoben und verarbeitet, wenn Sie uns diese zur Durchf&uuml;hrung eines Vertrages oder bei der Er&ouml;ffnung eines Kundenkontos mitteilen. Welche Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Eine L&ouml;schung Ihres Kundenkontos ist jederzeit m&ouml;glich und kann durch eine Nachricht an die o.g. Adresse des Verantwortlichen erfolgen. Wir speichern und verwenden die von Ihnen mitgeteilten Daten zur Vertragsabwicklung. Nach vollst&auml;ndiger Abwicklung des Vertrages oder L&ouml;schung Ihres Kundenkontos werden Ihre Daten mit R&uuml;cksicht auf steuer- und handelsrechtliche Aufbewahrungsfristen gesperrt und nach Ablauf dieser Fristen gel&ouml;scht, sofern Sie nicht ausdr&uuml;cklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder eine gesetzlich erlaubte weitere Datenverwendung von unserer Seite vorbehalten wurde, &uuml;ber die wir Sie nachstehend entsprechend informieren.</p>
            
            <h2>6) Nutzung Ihrer Daten zur Direktwerbung</h2>
            
            <p><b>6.1</b>&thinsp;Anmeldung zu unserem E-Mail-Newsletter</p>
            
            <p>Wenn Sie sich zu unserem E-Mail Newsletter anmelden, &uuml;bersenden wir Ihnen regelm&auml;&szlig;ig Informationen zu unseren Angeboten. Pflichtangabe f&uuml;r die &Uuml;bersendung des Newsletters ist allein Ihre E-Mailadresse. Die Angabe weiterer evtl. Daten ist freiwillig und wird verwendet, um Sie pers&ouml;nlich ansprechen zu k&ouml;nnen. F&uuml;r den Versand des Newsletters verwenden wir das sog. Double Opt-in Verfahren. Dies bedeutet, dass wir Ihnen erst dann einen E-Mail Newsletter &uuml;bermitteln werden, wenn Sie uns ausdr&uuml;cklich best&auml;tigt haben, dass Sie in den Versand von Newsletter einwilligen. Wir schicken Ihnen dann eine Best&auml;tigungs-E-Mail, mit der Sie gebeten werden durch Anklicken eines entsprechenden Links zu best&auml;tigen, dass Sie k&uuml;nftig Newsletter erhalten wollen.</p>
            
            <p>Mit der Aktivierung des Best&auml;tigungslinks erteilen Sie uns Ihre Einwilligung f&uuml;r die Nutzung Ihrer personenbezogenen Daten gem&auml;&szlig; Art. 6 Abs. 1 lit. a DSGVO. Bei der Anmeldung zum Newsletter speichern wir Ihre vom Internet Service-Provider (ISP) eingetragene IP-Adresse sowie das Datum und die Uhrzeit der Anmeldung, um einen m&ouml;glichen Missbrauch Ihrer E-Mail- Adresse zu einem sp&auml;teren Zeitpunkt nachvollziehen zu k&ouml;nnen. Die von uns bei der Anmeldung zum Newsletter erhobenen Daten werden ausschlie&szlig;lich f&uuml;r Zwecke der werblichen Ansprache im Wege des Newsletters benutzt. Sie k&ouml;nnen den Newsletter jederzeit &uuml;ber den daf&uuml;r vorgesehenen Link im Newsletter oder durch entsprechende Nachricht an den eingangs genannten Verantwortlichen abbestellen. Nach erfolgter Abmeldung wird Ihre E-Mailadresse unverz&uuml;glich in unserem Newsletter-Verteiler gel&ouml;scht, soweit Sie nicht ausdr&uuml;cklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine dar&uuml;berhinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und &uuml;ber die wir Sie in dieser Erkl&auml;rung informieren.</p>
            
            <p><b>6.2</b>&thinsp;Newsletter-Versand via MailChimp</p>
            
            <p>Der Versand unserer E-Mail-Newsletter erfolgt &uuml;ber den technischen Dienstleister The Rocket Science Group, LLC d/b/a MailChimp, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA (http://www.mailchimp.com/), an die wir Ihre bei der Newsletteranmeldung bereitgestellten Daten weitergeben. Diese Weitergabe erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO und dient unserem berechtigten Interesse an der Verwendung eines werbewirksamen, sicheren und nutzerfreundlichen Newslettersystems. Bitte beachten Sie, dass Ihre Daten in der Regel an einen Server von MailChimp in den USA &uuml;bertragen und dort gespeichert werden.</p>
            
            <p>MailChimp verwendet diese Informationen zum Versand und zur statistischen Auswertung der Newsletter in unserem Auftrag. F&uuml;r die Auswertung beinhalten die versendeten E-Mails sog. Web-Beacons bzw. Trackings-Pixel, die Ein-Pixel-Bilddateien darstellen, die auf unserer Website gespeichert sind. So kann festgestellt werden, ob eine Newsletter-Nachricht ge&ouml;ffnet und welche Links ggf. angeklickt wurden. Au&szlig;erdem werden technische Informationen erfasst (z.B. Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Die Daten werden ausschlie&szlig;lich pseudonymisiert erhoben und werden nicht mir Ihren weiteren pers&ouml;nlichen Daten verkn&uuml;pft, eine direkte Personenbeziehbarkeit wird ausgeschlossen. Diese Daten dienen ausschlie&szlig;lich der statistischen Analyse von Newsletterkampagnen. Die Ergebnisse dieser Analysen k&ouml;nnen genutzt werden, um k&uuml;nftige Newsletter besser an die Interessen der Empf&auml;nger anzupassen.</p>
            
            <p>Wenn Sie der Datenanalyse zu statistischen Auswertungszwecken widersprechen m&ouml;chten, m&uuml;ssen Sie den Newsletterbezug abbestellen.</p>
            
            <p>Des Weiteren kann MailChimp diese Daten gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO selbst aufgrund seines eigenen berechtigten Interesses an der bedarfsgerechten Ausgestaltung und der Optimierung des Dienstes sowie zu Marktforschungszwecken nutzen, um etwa zu bestimmen, aus welchen L&auml;ndern die Empf&auml;nger kommen. MailChimp nutzt die Daten unserer Newsletterempf&auml;nger jedoch nicht, um diese selbst anzuschreiben oder sie an Dritte weiterzugeben.</p>
            
            <p>Zum Schutz Ihrer Daten in den USA haben wir mit MailChimp einen Datenverarbeitungsauftrag (&bdquo;Data-Processing-Agreement&ldquo;) auf Basis der Standardvertragsklauseln der Europ&auml;ischen Kommission abgeschlossen, um die &Uuml;bermittlung Ihrer personenbezogenen Daten an MailChimp zu erm&ouml;glichen. Dieser Datenverarbeitungsvertrag kann bei Interesse unter nachstehender Internetadresse eingesehen werden: http://mailchimp.com/legal/forms/data-processing-agreement/.</p>
            
            <p>MailChimp ist dar&uuml;ber hinaus unter dem us-europ&auml;ischen Datenschutzabkommen &bdquo;Privacy Shield&ldquo; zertifiziert und verpflichtet sich damit, die EU-Datenschutzvorgaben einzuhalten.</p>
            
            <p>Die Datenschutzbestimmungen von MailChimp k&ouml;nnen Sie hier einsehen: https://mailchimp.com/legal/privacy/</p>
            
            <h2>7) Datenverarbeitung zur Bestellabwicklung</h2>
            
            <p><b>7.1</b>&thinsp;Zur Abwicklung Ihrer Bestellung arbeiten wir mit dem / den nachstehenden Dienstleistern zusammen, die uns ganz oder teilweise bei der Durchf&uuml;hrung geschlossener Vertr&auml;ge unterst&uuml;tzen. An diese Dienstleister werden nach Ma&szlig;gabe der folgenden Informationen gewisse personenbezogene Daten &uuml;bermittelt.</p>
            
            <p>Die von uns erhobenen personenbezogenen Daten werden im Rahmen der Vertragsabwicklung an das mit der Lieferung beauftragte Transportunternehmen weitergegeben, soweit dies zur Lieferung der Ware erforderlich ist. Ihre Zahlungsdaten geben wir im Rahmen der Zahlungsabwicklung an das beauftragte Kreditinstitut weiter, sofern dies f&uuml;r die Zahlungsabwicklung erforderlich ist. Sofern Zahlungsdienstleister eingesetzt werden, informieren wir hier&uuml;ber nachstehend explizit. Die Rechtsgrundlage f&uuml;r die Weitergabe der Daten ist hierbei Art. 6 Abs. 1 lit. b DSGVO.</p>
            
            <p><b>7.2</b>&thinsp;Verwendung von Paymentdienstleistern (Zahlungsdienstleister)</p>
            
            <p>- Paypal<br />
            Bei Zahlung via PayPal, Kreditkarte via PayPal, Lastschrift via PayPal oder &ndash; falls angeboten - &quot;Kauf auf Rechnung&quot; oder &bdquo;Ratenzahlung&ldquo; via PayPal geben wir Ihre Zahlungsdaten im Rahmen der Zahlungsabwicklung an die PayPal (Europe) S.a.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg (nachfolgend &quot;PayPal&quot;), weiter. Die Weitergabe erfolgt gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO und nur insoweit, als dies f&uuml;r die Zahlungsabwicklung erforderlich ist.<br />
            PayPal beh&auml;lt sich f&uuml;r die Zahlungsmethoden Kreditkarte via PayPal, Lastschrift via PayPal oder &ndash; falls angeboten - &quot;Kauf auf Rechnung&quot; oder &bdquo;Ratenzahlung&ldquo; via PayPal die Durchf&uuml;hrung einer Bonit&auml;tsauskunft vor. Hierf&uuml;r werden Ihre Zahlungsdaten gegebenenfalls gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO auf Basis des berechtigten Interesses von PayPal an der Feststellung Ihrer Zahlungsf&auml;higkeit an Auskunfteien weitergegeben. Das Ergebnis der Bonit&auml;tspr&uuml;fung in Bezug auf die statistische Zahlungsausfallwahrscheinlichkeit verwendet PayPal zum Zwecke der Entscheidung &uuml;ber die Bereitstellung der jeweiligen Zahlungsmethode. Die Bonit&auml;tsauskunft kann Wahrscheinlichkeitswerte enthalten (sog. Score-Werte). Soweit Score-Werte in das Ergebnis der Bonit&auml;tsauskunft einflie&szlig;en, haben diese ihre Grundlage in einem wissenschaftlich anerkannten mathematisch-statistischen Verfahren. In die Berechnung der Score-Werte flie&szlig;en unter anderem, aber nicht ausschlie&szlig;lich, Anschriftendaten ein. Weitere datenschutzrechtliche Informationen, unter anderem zu den verwendeten Auskunfteien, entnehmen Sie bitte der Datenschutzerkl&auml;rung von PayPal: https://www.paypal.com/de/webapps/mpp/ua/privacy-full<br />
            Sie k&ouml;nnen dieser Verarbeitung Ihrer Daten jederzeit durch eine Nachricht an PayPal widersprechen. Jedoch bleibt PayPal ggf. weiterhin berechtigt, Ihre personenbezogenen Daten zu verarbeiten, sofern dies zur vertragsgem&auml;&szlig;en Zahlungsabwicklung erforderlich ist.</p>
            
            <h2>8) Verwendung von Sozialen Medien: Videos</h2>
            
            <p>Verwendung von Youtube-Videos</p>
            
            <p>Diese Website nutzt die Youtube-Einbettungsfunktion zur Anzeige und Wiedergabe von Videos des Anbieters &bdquo;Youtube&ldquo;, der zu der Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (&bdquo;Google&ldquo;) geh&ouml;rt.</p>
            
            <p>Hierbei wird der erweiterte Datenschutzmodus verwendet, der nach Anbieterangaben eine Speicherung von Nutzerinformationen erst bei Wiedergabe des/der Videos in Gang setzt. Wird die Wiedergabe eingebetteter Youtube-Videos gestartet, setzt der Anbieter &bdquo;Youtube&ldquo; Cookies ein, um Informationen &uuml;ber das Nutzerverhalten zu sammeln. Hinweisen von &bdquo;Youtube&ldquo; zufolge dienen diese unter anderem dazu, Videostatistiken zu erfassen, die Nutzerfreundlichkeit zu verbessern und missbr&auml;uchliche Handlungsweisen zu unterbinden. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet, wenn Sie ein Video anklicken. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht w&uuml;nschen, m&uuml;ssen Sie sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten (selbst f&uuml;r nicht eingeloggte Nutzer) als Nutzungsprofile und wertet diese aus. Eine solche Auswertung erfolgt insbesondere gem&auml;&szlig; Art. 6 Abs. 1 lit.f DSGVO auf Basis der berechtigten Interessen von Google an der Einblendung personalisierter Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Aus&uuml;bung dessen an YouTube richten m&uuml;ssen.</p>
            
            <p>Unabh&auml;ngig von einer Wiedergabe der eingebetteten Videos wird bei jedem Aufruf dieser Website eine Verbindung zum Google-Netzwerk &bdquo;DoubleClick&ldquo; aufgenommen, was ohne unseren Einfluss weitere Datenverarbeitungsvorg&auml;nge ausl&ouml;sen kann.</p>
            
            <p>Google LLC mit Sitz in den USA ist f&uuml;r das us-europ&auml;ische Datenschutz&uuml;bereinkommen &bdquo;Privacy Shield&ldquo; zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gew&auml;hrleistet.</p>
            
            <p>Weitere Informationen zum Datenschutz bei &bdquo;YouTube&ldquo; finden Sie in der Datenschutzerkl&auml;rung des Anbieters unter: https://www.google.de/intl/de/policies/privacy</p>
            
            <h2>9) Webanalysedienste</h2>
            
            <p>Matomo (ehemals Piwik)</p>
            
            <p>Auf dieser Website werden unter Einsatz der Webanalysedienst-Software Matomo (www.matomo.org), einem Dienst des Anbieters InnoCraft Ltd., 150 Willis St, 6011 Wellington, Neuseeland, (&bdquo;Mataomo&ldquo;) auf Basis unseres berechtigten Interesses an der statistischen Analyse des Nutzerverhaltens zu Optimierungs- und Marketingzwecken gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO Daten gesammelt und gespeichert. Aus diesen Daten k&ouml;nnen zum selben Zweck pseudonymisierte Nutzungsprofile erstellt und ausgewertet werden. Hierzu k&ouml;nnen Cookies eingesetzt werden. Bei Cookies handelt es sich um kleine Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des Seitenbesuchers gespeichert werden. Die Cookies erm&ouml;glichen unter anderem die Wiedererkennung des Internet-Browsers. Die mit der Matomo-Technologie erhobenen Daten (einschlie&szlig;lich Ihrer pseudonymisierten IP-Adresse) werden auf unseren Servern verarbeitet.</p>
            
            <p>Die durch das Cookie erzeugten Informationen im pseudonymen Nutzerprofil werden nicht dazu benutzt, den Besucher dieser Website pers&ouml;nlich zu identifizieren und nicht mit personenbezogenen Daten &uuml;ber den Tr&auml;ger des Pseudonyms zusammengef&uuml;hrt.</p>
            
            <p>Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem Besuch nicht einverstanden sind, dann k&ouml;nnen Sie der Speicherung und Nutzung nachfolgend per Mausklick jederzeit widersprechen. In diesem Fall wird in Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten erhebt. Bitte beachten Sie, dass die vollst&auml;ndige L&ouml;schung Ihrer Cookies zur Folge hat, dass auch das Opt-Out-Cookie gel&ouml;scht wird und ggf. von Ihnen erneut aktiviert werden muss.</p>
            
            <iframe frameborder="no" height="200px" scrolling="auto" style="border: 2px solid #bec6c8; width:100%; padding:2px" src="https://piwik.sensebox.kaufen/index.php?module=CoreAdminHome&amp;action=optOut&amp;idsite=25&amp;language=de"></iframe>
        
            <h2>10) Rechte des Betroffenen</h2>
            
            <p><b>10.1</b>&thinsp;Das geltende Datenschutzrecht gew&auml;hrt Ihnen gegen&uuml;ber dem Verantwortlichen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten umfassende Betroffenenrechte (Auskunfts- und Interventionsrechte), &uuml;ber die wir Sie nachstehend informieren:</p>
            
            <ul>
            <li>Auskunftsrecht gem&auml;&szlig; Art. 15 DSGVO: Sie haben insbesondere ein Recht auf Auskunft &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten, die Verarbeitungszwecke, die Kategorien der verarbeiteten personenbezogenen Daten, die Empf&auml;nger oder Kategorien von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer bzw. die Kriterien f&uuml;r die Festlegung der Speicherdauer, das Bestehen eines Rechts auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde bei einer Aufsichtsbeh&ouml;rde, die Herkunft Ihrer Daten, wenn diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling und ggf. aussagekr&auml;ftige Informationen &uuml;ber die involvierte Logik und die Sie betreffende Tragweite und die angestrebten Auswirkungen einer solchen Verarbeitung, sowie Ihr Recht auf Unterrichtung, welche Garantien gem&auml;&szlig; Art. 46 DSGVO bei Weiterleitung Ihrer Daten in Drittl&auml;nder bestehen;</li>
            <li>Recht auf Berichtigung gem&auml;&szlig; Art. 16 DSGVO: Sie haben ein Recht auf unverz&uuml;gliche Berichtigung Sie betreffender unrichtiger Daten und/oder Vervollst&auml;ndigung Ihrer bei uns gespeicherten unvollst&auml;ndigen Daten;</li>
            <li>Recht auf L&ouml;schung gem&auml;&szlig; Art. 17 DSGVO: Sie haben das Recht, die L&ouml;schung Ihrer personenbezogenen Daten bei Vorliegen der Voraussetzungen des Art. 17 Abs. 1 DSGVO zu verlangen. Dieses Recht besteht jedoch insbesondere dann nicht, wenn die Verarbeitung zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung einer rechtlichen Verpflichtung, aus Gr&uuml;nden des &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist;</li>
            <li>Recht auf Einschr&auml;nkung der Verarbeitung gem&auml;&szlig; Art. 18 DSGVO: Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, solange die von Ihnen bestrittene Richtigkeit Ihrer Daten &uuml;berpr&uuml;ft wird, wenn Sie eine L&ouml;schung Ihrer Daten wegen unzul&auml;ssiger Datenverarbeitung ablehnen und stattdessen die Einschr&auml;nkung der Verarbeitung Ihrer Daten verlangen, wenn Sie Ihre Daten zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen, nachdem wir diese Daten nach Zweckerreichung nicht mehr ben&ouml;tigen oder wenn Sie Widerspruch aus Gr&uuml;nden Ihrer besonderen Situation eingelegt haben, solange noch nicht feststeht, ob unsere berechtigten Gr&uuml;nde &uuml;berwiegen;</li>
            <li>Recht auf Unterrichtung gem&auml;&szlig; Art. 19 DSGVO: Haben Sie das Recht auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung gegen&uuml;ber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empf&auml;ngern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder L&ouml;schung der Daten oder Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unm&ouml;glich oder ist mit einem unverh&auml;ltnism&auml;&szlig;igen Aufwand verbunden. Ihnen steht das Recht zu, &uuml;ber diese Empf&auml;nger unterrichtet zu werden.</li>
            <li>Recht auf Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 DSGVO: Sie haben das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesebaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen, soweit dies technisch machbar ist;</li>
            <li>Recht auf Widerruf erteilter Einwilligungen gem&auml;&szlig; Art. 7 Abs. 3 DSGVO: Sie haben das Recht, eine einmal erteilte Einwilligung in die Verarbeitung von Daten jederzeit mit Wirkung f&uuml;r die Zukunft zu widerrufen. Im Falle des Widerrufs werden wir die betroffenen Daten unverz&uuml;glich l&ouml;schen, sofern eine weitere Verarbeitung nicht auf eine Rechtsgrundlage zur einwilligungslosen Verarbeitung gest&uuml;tzt werden kann. Durch den Widerruf der Einwilligung wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht ber&uuml;hrt;</li>
            <li>Recht auf Beschwerde gem&auml;&szlig; Art. 77 DSGVO: Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verst&ouml;&szlig;t, haben Sie - unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs - das Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat Ihres Aufenthaltsortes, Ihres Arbeitsplatzes oder des Ortes des mutma&szlig;lichen Versto&szlig;es.</li>
            </ul>
            
            <p><b>10.2</b>&thinsp;WIDERSPRUCHSRECHT</p>
            
            <p>WENN WIR IM RAHMEN EINER INTERESSENABW&Auml;GUNG IHRE PERSONENBEZOGENEN DATEN AUFGRUND UNSERES &Uuml;BERWIEGENDEN BERECHTIGTEN INTERESSES VERARBEITEN, HABEN SIE DAS JEDERZEITIGE RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIESE VERARBEITUNG WIDERSPRUCH MIT WIRKUNG F&Uuml;R DIE ZUKUNFT EINZULEGEN.<br />
            MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN. EINE WEITERVERARBEITUNG BLEIBT ABER VORBEHALTEN, WENN WIR ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN K&Ouml;NNEN, DIE IHRE INTERESSEN, GRUNDRECHTE UND GRUNDFREIHEITEN &Uuml;BERWIEGEN, ODER WENN DIE VERARBEITUNG DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN DIENT.</p>
            
            <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VON UNS VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN. SIE K&Ouml;NNEN DEN WIDERSPRUCH WIE OBEN BESCHRIEBEN AUS&Uuml;BEN.</p>
            
            <p>MACHEN SIE VON IHREM WIDERSPRUCHSRECHT GEBRAUCH, BEENDEN WIR DIE VERARBEITUNG DER BETROFFENEN DATEN ZU DIREKTWERBEZWECKEN.</p>
            
            <h2>11) Dauer der Speicherung personenbezogener Daten</h2>
            
            <p>Die Dauer der Speicherung von personenbezogenen Daten bemisst sich anhand der jeweiligen gesetzlichen Aufbewahrungsfrist (z.B. handels- und steuerrechtliche Aufbewahrungsfristen). Nach Ablauf der Frist werden die entsprechenden Daten routinem&auml;&szlig;ig gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder Vertragsanbahnung erforderlich sind und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.</p>
        
        </div>
</div>