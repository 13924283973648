<div class="site ">
  <div class="container site-title-container">
    <div class="columns">
      <div class="column is-half sb-white-text">
        <h1
          class="site-title"
          [innerHTML]="'AUSZEICHNUNGEN_HTML' | translate"
        ></h1>
        <h3>
          {{ "AUSZEICHNUNGEN_DESCRIPTION" | translate }}
        </h3>
      </div>
    </div>
    <img class="floating-reedu-logo" src="assets/img/logos/logo_orange.svg" />
  </div>
  <div class="stripes-container">
    <div class="stripes-zig-zag">
      <div class="container">
        <div class="card" *ngFor="let award of awards; let i = index">
          <div class="card-content">
            <div class="container">
              <div class="is-multiline awards-container sb-red-text">
                <div class="image-container">
                  <img
                    *ngIf="award.image"
                    src="{{ award.image }}"
                    alt="image"
                  />
                </div>
                <div class="desc">
                  <h1 class="title">{{ award.title }}</h1>
                  <!-- <h2 class="subtitle">{{ award.description }}</h2> -->
                </div>
                <div class="button-column">
                  <button class="down-button" (click)="togglePanel(i)">
                    <img src="/assets/img/logos/logo_black.svg" />
                  </button>
                </div>
              </div>
              <div class="descripton-panel" *ngIf="openPanel === i">
                <p>{{award.desc}}</p>
                <div class="pt-2">
                  <a class="red-link" *ngIf="award.link" [href]="award.link" target="_blank">{{'MEHR_DAZU' | translate}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
