<div class="site sb-white-text red-section">
  <div class="stripes-container">
    <div class="stripes">
      <div class="container site-title-container">
        <h1 class="site-title">Jobs</h1>
        <h3 class="reedu-job-headline" [innerHTML]="'REEDU_JOBS_HEADLINE' | translate"></h3>
        <!-- <h3>Coming soon... </h3> -->
      </div>
      <div class="container content">
        <p class="reedu-desc" [innerHTML]="'REEDU_BEST_JOB_WO_GIBT' | translate">
          
        </p>

        <div *ngFor="let job of jobs" class="job-container">
          <p>{{ job.date }}</p>
          <h1>{{ job.title }}</h1>
          <h4>{{ job.description }}</h4>
          <a href="/job/{{ job.filename }}">
            <button class="re-button">
              <span>{{ "MEHR_DAZU" | translate }}</span>
              <img src="/assets/img/logos/logo_black.svg" />
            </button>
          </a>
          <hr />
        </div>
      </div>
    </div>
  </div>
</div>
