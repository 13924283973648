<div class="background-container" [class.background]="background">
  <div class="container contact-card-container">
    <div class="card">
      <div class="card-content">
        <div class="columns">
          <div
            class="column is-two-thirds is-flex is-flex-direction-row is-flex-wrap-wrap is-justify-content-center is-align-items-center"
          >
            <img src="assets/img/logos/logo_orange.svg" />
            <h1 class="title contact-title orange">
              {{ "WIR_FREUEN_UNS_UEBER_FRAGEN_ODER_ANREGUNGEN" | translate }}
            </h1>
          </div>
          <div
            class="column is-flex is-flex-direction-column is-justify-content-space-evenly"
          >
            <div>
              <span class="icon-text has-text-primary">
                <span class="icon">
                  <i class="fas fa-phone"></i>
                </span>
                <h6 class="title is-6 sb-red-text">
                  {{ "ANRUFEN" | translate }}
                </h6>
              </span>
              <h6 class="title is-6">
                <a href="tel:+49 251 98119797">+49 251 98119797</a>
              </h6>
            </div>
            <div>
              <span class="icon-text has-text-primary">
                <span class="icon">
                  <i class="fas fa-envelope"></i>
                </span>
                <h6 class="title is-6 sb-red-text">
                  {{ "SCHREIBEN" | translate }}
                </h6>
              </span>
              <h6 class="title is-6">
                <a href="mailto:kontakt@reedu.de">kontakt@reedu.de</a>
              </h6>
            </div>
            <div>
              <span class="icon-text has-text-primary">
                <span class="icon">
                  <i class="fas fa-map-marker-alt"></i>
                </span>
                <h6 class="title is-6 sb-red-text">
                  {{ "BESUCHEN" | translate }}
                </h6>
              </span>
              <h6 class="title is-6">Von-Steuben-Str. 21, 48143 Münster</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
