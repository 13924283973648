<div class="site sb-white-text red-section">
    <div class="stripes-container">
      <div class="stripes">
        <div class="container">
            <h1 class="site-title">{{'UNSER_ANGEBOT' | translate}}</h1>
        </div>
        <div class="container">
          <re-tabs [tabs]="angeboteTabs" [selectedTab]="selectedTab"></re-tabs>
        </div>
      </div>
    </div>
  </div>
  