<div class="hero is-fullheight is-primary">
  <img
    alt=""
    class="is-transparent hero-background"
    src="assets/img/start/hero2.jpg"
  />
  <div class="hero-body">
    <div
      style="max-width: 100%"
      class="container is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
    >
      <img src="assets/img/logos/logo_white.svg" />
      <h1 class="has-text-centered">
        {{ "STARTPAGE_HERO_HEADLINE" | translate }}
      </h1>
      <h1 class="typed-element nowrap hast-text-centered"></h1>
      <!-- <h1 class="nowrap hast-text-centered">Geografische Informationssysteme</h1>   -->
    </div>
  </div>
  <div class="scroller-arrow bounce">
    <img src="assets/img/logos/logo_outline.svg" (click)="onScrollClick()" />
  </div>
</div>

<section id="what-we-do" class="sb-red-text">
  <div class="container px-3 py-5">
    <div class="columns is-tablet is-3 my-2">
      <div class="column is-7 text-container">
        <div class="sb-flex-container-column white-section padding-6-resp">
          <div class="height100flex">
            <h1 class="pb-4">{{ "STARTPAGE_HEADLINE" | translate }}</h1>
            <h3 class="headline-underline">
              <span>{{ "ABOUT_US" | translate }}</span>
            </h3>
            <p class="bold-text-2 py-2">
              {{ "WHAT_WE_DO" | translate }}
            </p>
            <p class="bold-text-1 line-height-small py-3">
              {{ "WHAT_WE_DO_IMPORTANT" | translate }}
            </p>
            <div>
              <a
                href="https://sensebox.de"
                class="is-flex is-align-items-center is-flex-wrap-wrap"
              >
                <img
                  class="sensebox-logo"
                  src="assets/img/logos/sensebox/Logo_home-of-sensebox_long.svg"
                />
              </a>
              <p class="bold-text-2">
                {{ "WHAT_WE_DO_SENSEBOX_KIT" | translate }}
              </p>
            </div>
            <a href="https://sensebox.kaufen">
              <button class="re-button">
                <span>{{ "MEHR_DAZU" | translate }}</span>
                <img src="/assets/img/logos/logo_black.svg" />
              </button>
            </a>
          </div>
        </div>
        <div class="reedu-floating-logo-red">
          <img src="assets/img/logos/logo_orange.svg" />
        </div>
      </div>
      <div class="column is-5">
        <div class="white-section padding-6-resp height-100">
          <h3 class="headline-underline"><span>{{ "ANGEBOTE" | translate }}</span></h3>
          <div 
            class="topic-block" 
            [routerLink]="['angebote', 'hardware']">
            <span
              [innerHTML]='"HARDWARE_ENTWICKLUNG" | translate '>
            </span>
          </div>
          <div 
            class="topic-block" 
            [routerLink]="['angebote', 'software']">
            <span
            [innerHTML]='"SOFTWARE_ENTWICKLUNG" | translate '>
            {{ "SOFTWARE_ENTWICKLUNG" | translate }}
            </span>
          </div>
          <div
            class="topic-block"
            [routerLink]="['angebote', 'veranstaltungen']"
          >
            {{ "SCHULUNGEN" | translate }},<br />
            {{ "WORKSHOPS" | translate }},<br />
            {{ "HACKATHONS" | translate }}
          </div>
          <div class="topic-block" [routerLink]="['angebote', 'lehrmaterial']">
            {{ "LEHRMATERIAL" | translate }}
          </div>
          <div class="topic-block" [routerLink]="['angebote', 'forschung']">
            {{ "FORSCHUNG" | translate }}
          </div>
          <div class="topic-block" [routerLink]="['angebote', 'beratung']">
            {{ "BERATUNG" | translate }}
          </div>
          <div class="py-5">
            <button class="re-button" [routerLink]="['angebote']">
              <span>{{ "MEHR_DAZU" | translate }}</span>
              <img src="/assets/img/logos/logo_black.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="experience" class="black-section sb-white-text">
  <div class="container px-3 py-5">
    <div class="container">
      <h1 class="py-6">{{ "EXPERIENCE" | translate }}</h1>

      <div class="columns">
        <div class="column is-6 sb-item" [routerLink]="['portfolio']">
          <div class="sb-item-inner">
            <h3 class="headline-underline">
              <span>{{ "PORTFOLIO" | translate }}</span>
            </h3>
            <p>
              {{ "PORTFOLIO_TEXT" | translate }}
            </p>
            <div class="sb-image-caption">
              <img src="/assets/img/start/startseite_projekte.jpg" />
              <div class="sb-caption">
                <h3>
                  {{ "PORTFOLIO_ANSEHEN" | translate }}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-6 sb-item" [routerLink]="['forschung']">
          <div class="sb-item-inner">
            <h3 class="headline-underline">
              <span>{{ "FORSCHUNG" | translate }}</span>
            </h3>
            <p>
              {{ "FORSCHUNG_TEXT" | translate }}
            </p>
            <div class="sb-image-caption">
              <img src="/assets/img/start/startseite_forschung.jpg" />
              <div class="sb-caption">
                <h3>
                  {{ "FORSCHUNG_ANSEHEN" | translate }}
                </h3>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
<re-contact-card [background]="true"></re-contact-card>

<section id="contact" class="red-section"></section>
