<nav
  class="navbar is-transparent is-fixed-top"
  [class.scrolled]="scroll != 0"
  role="navigation"
  aria-label="main navigation"
>
  <div class="re-mobile-menu">
    <a
      role="button"
      class="navbar-burger"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbar-mobile"
      *ngIf="!mobileOpen"
      (click)="mobileOpen = !mobileOpen"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
    <a
      role="button"
      class="re-navbar-close"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbar-mobile"
      *ngIf="mobileOpen"
      (click)="mobileOpen = !mobileOpen"
    >
      <i class="fa fa-times"></i>
    </a>
    <a class="navbar-reedu-logo" [routerLink]="['']">
      <img src="assets/img/logos/logo_white.svg" />
    </a>
    <a class="navbar-item re-navbar-item">
      <a
        class="lang-select"
        [class.active]="currentLang === 'de'"
        (click)="selectLang('de')"
        >DE</a
      >
      <a
        [class.active]="currentLang === 'en'"
        class="lang-select"
        (click)="selectLang('en')"
        >EN</a
      >
    </a>
  </div>

  <div
    id="navbar-mobile"
    [class.is-active]="mobileOpen"
    class="navbar-menu"
    (click)="close()"
  >
    <div class="navbar-start">
      <a class="navbar-item re-lang-select">
        <a
          class="lang-select"
          [class.active]="currentLang === 'de'"
          (click)="selectLang('de')"
          >DE</a
        >
        <a
          [class.active]="currentLang === 'en'"
          class="lang-select"
          (click)="selectLang('en')"
          >EN</a
        >
      </a>
      <a
        [routerLink]="['angebote']"
        routerLinkActive="active"
        class="navbar-item"
      >
        {{ "ANGEBOTE" | translate }}
      </a>
      <a
        [routerLink]="['portfolio']"
        routerLinkActive="active"
        class="navbar-item"
      >
        {{ "PORTFOLIO" | translate }}
      </a>
    </div>
    <div class="navbar-reedu" [class.scrolled]="scroll != 0">
      <a class="navbar-reedu-item" [routerLink]="['']">
        <img src="assets/img/logos/logo_small_text_white.png" />
      </a>
      <a class="navbar-reedu-item-small" [routerLink]="['']">
        <img src="assets/img/logos/logo_white.svg" />
      </a>
    </div>
    <div class="navbar-end">
      <a
        [routerLink]="['forschung']"
        routerLinkActive="active"
        class="navbar-item"
      >
        {{ "FORSCHUNG" | translate }}
      </a>

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">{{ "ABOUT_US" | translate }} </a>
        <div class="navbar-dropdown">
          <a
            [routerLink]="['philosophie']"
            routerLinkActive="active"
            class="navbar-item"
          >
            {{ "PHILOSOPHIE" | translate }}
          </a>
          <a
            [routerLink]="['auszeichnungen']"
            routerLinkActive="active"
            class="navbar-item"
          >
            {{ "AUSZEICHNUNGEN" | translate }}
          </a>
          <a
            [routerLink]="['team']"
            routerLinkActive="active"
            class="navbar-item"
          >
            {{ "TEAM" | translate }}
          </a>
          <a
            [routerLink]="['jobs']"
            routerLinkActive="active"
            class="navbar-item"
          >
            Jobs
          </a>
        </div>
      </div>
      <div class="mobile-spacer">
        
      </div>
      <a
        [routerLink]="['contact']"
        routerLinkActive="active"
        class="navbar-item re-mobile-navitem-only"
      >
        {{ "KONTAKT" | translate }}
      </a>
      <a
        [routerLink]="['imprint']"
        routerLinkActive="active"
        class="navbar-item re-mobile-navitem-only"
      >
        {{ "IMPRESSUM" | translate }}
      </a>
      <a
        [routerLink]="['privacy']"
        routerLinkActive="active"
        class="navbar-item re-mobile-navitem-only"
      >
        {{ "DATENSCHUTZ" | translate }}
      </a>
      <div class="is-flex">
        <a href="https://de.linkedin.com/company/reedu-de" class="navbar-item">
          <span class="icon">
            <i class="fab fa-lg fa-linkedin"></i>
          </span>
        </a>
        <a
          href="https://github.com/reedu-reengineering-education"
          class="navbar-item"
        >
          <span class="icon">
            <i class="fab fa-lg fa-github"></i>
          </span>
        </a>
      </div>
    </div>
  </div>

  <!-- <div class="navbar-brand">
      <a class="navbar-item" href="https://bulma.io">
        <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28">
      </a>
  
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
  
    <div id="navbarBasicExample" class="navbar-menu">
      <div class="navbar-start">
        <a class="navbar-item">
          Home
        </a>
  
        <a class="navbar-item">
          Documentation
        </a>
  
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            More
          </a>
  
          <div class="navbar-dropdown">
            <a class="navbar-item">
              About
            </a>
            <a class="navbar-item">
              Jobs
            </a>
            <a class="navbar-item">
              Contact
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item">
              Report an issue
            </a>
          </div>
        </div>
      </div>
  
      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-primary">
              <strong>Sign up</strong>
            </a>
            <a class="button is-light">
              Log in
            </a>
          </div>
        </div>
      </div>
    </div> -->
</nav>
