<div class="card-container">
  <div class="card">
    <div class="card-image">
      <figure class="image is-3by2">
        <img src="{{ project.img }}" alt="Project Image" loading="lazy" />
      </figure>
      <div class="re-tags">
        <div *ngFor="let tag of project.angebote">
          <span class="re-tag">{{ tag | translate }}</span>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4 red-title">{{ project.name }}</p>
          <p class="subtitle is-6">
            <span *ngFor="let year of project.year">{{ year }} </span>
          </p>
        </div>
      </div>
      <div class="content">
        {{ project.desc | trimPipe}}
      </div>
    </div>
    <footer class="card-footer">
      <button
        [routerLink]="['/portfolio', url]"
        class="footer-button button is-pulled-right"
      >
        <span>{{ "MEHR_DAZU" | translate }}</span>
        <span class="icon is-small">
          <img src="assets/img/logos/logo_black.svg" />
        </span>
      </button>
    </footer>
  </div>
</div>
