<div class="site red-section">
  <div class="container white-section margin-left-container">
    <div id="left-logo">
      <img src="assets/img/logos/logo_orange.svg" />
    </div>
    <h1 class="">
      {{ "WIR_FREUEN_UNS_UEBER_FRAGEN_ODER_ANREGUNGEN" | translate }}
    </h1>

    <div class="columns py-6">
      <div class="contact-list py-6 column is-6">
        <div class="py-1">
          <span class="icon-text has-text-primary">
            <span class="icon">
              <i class="fas fa-phone"></i>
            </span>
            <h6 class="title is-6 sb-red-text">
              {{ "ANRUFEN" | translate }}
            </h6>
          </span>
          <h6 class="title is-6">
            <a href="tel:+49 251 98119797">+49 251 98119797</a>
          </h6>
        </div>
        <div class="py-1">
          <span class="icon-text has-text-primary">
            <span class="icon">
              <i class="fas fa-envelope"></i>
            </span>
            <h6 class="title is-6 sb-red-text">
              {{ "SCHREIBEN" | translate }}
            </h6>
          </span>
          <h6 class="title is-6">
            <a href="mailto:kontakt@reedu.de">kontakt@reedu.de</a>
          </h6>
        </div>
        <div class="py-1">
          <span class="icon-text has-text-primary">
            <span class="icon">
              <i class="fas fa-map-marker-alt"></i>
            </span>
            <h6 class="title is-6 sb-red-text">
              {{ "BESUCHEN" | translate }}
            </h6>
          </span>
          <h6 class="title is-6">Von-Steuben-Str. 21, 48143 Münster</h6>
        </div>
        <div class="is-flex py-6">
          <a
            href="https://de.linkedin.com/company/reedu-de"
            class="navbar-item"
          >
            <span class="icon">
              <i class="fab fa-lg fa-linkedin"></i>
            </span>
          </a>
          <a
            href="https://github.com/reedu-reengineering-education"
            class="navbar-item"
          >
            <span class="icon">
              <i class="fab fa-lg fa-github"></i>
            </span>
          </a>
        </div>
      </div>
      <div class="column is-6 img-column">
        <mgl-map [style]="style" [zoom]="[14]" [center]="[7.631741, 51.95431]">
          <mgl-marker [lngLat]="[7.631741, 51.95431]"></mgl-marker>
        </mgl-map>
      </div>
    </div>
  </div>
</div>
