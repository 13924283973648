<div class="site black-section">
  <div class="container site-title-container p-4 sb-white-text">
    <div class="columns is-desktop">
      <div class="column is-desktop is-half">
        <h1 class="site-title">{{ "UNSERE_WORKSHOP_ANGEBOTE" | translate }}</h1>
        <h3>
          {{ "WORKSHOPS_DIFFERENT_FORMATS" | translate }}
        </h3>
      </div>
    </div>
  </div>
  
  <div class="stripes-container sb-white-text">
    <div class="stripes">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="workshop-type">
              <h3>{{ "FOR_TEACHERS" | translate }}</h3>
              <p>{{ "TEACHERS_TEXT" | translate }}</p>
              <ul>
                <li>{{ "TEACHERS1" | translate }}</li>
                <li>{{ "TEACHERS2" | translate }}</li>
                <li>{{ "TEACHERS3" | translate }}</li>
                <li>{{ "TEACHERS4" | translate }}</li>
                <li>{{ "TEACHERS5" | translate }}</li>
              </ul>
            </div>
          </div>
          <div class="column is-half">

          <div class="workshop-type">
            <h3>{{ "FOR_STUDENTS" | translate }}</h3>
            <p>{{ "STUDENTS_TEXT" | translate }}</p>
            <ul>
              <li>{{ "STUDENTS1" | translate }}</li>
              <li>{{ "STUDENTS2" | translate }}</li>
              <li>{{ "STUDENTS3" | translate }}</li>
              <li>{{ "STUDENTS4" | translate }}</li>
              <li>{{ "STUDENTS5" | translate }}</li>
            </ul>
          </div>
          </div>
          <div class="column is-half">

          <div class="workshop-type">
            <h3>{{ "FOR_PUPILS" | translate }}</h3>
            <p>{{ "PUPILS_TEXT" | translate }}</p>
            <ul>
              <li>{{ "PUPILS1" | translate }}</li>
              <li>{{ "PUPILS2" | translate }}</li>
              <li>{{ "PUPILS3" | translate }}</li>
              <li>{{ "PUPILS4" | translate }}</li>
              <li>{{ "PUPILS5" | translate }}</li>
            </ul>
          </div>
          </div>
          <div class="column is-half">

          <div class="workshop-type">
            <h3>{{ "FOR_CITIZENS" | translate }}</h3>
            <p>{{ "CITIZENS_TEXT" | translate }}</p>
            <ul>
              <li>{{ "CITIZENS1" | translate }}</li>
              <li>{{ "CITIZENS2" | translate }}</li>
              <li>{{ "CITIZENS3" | translate }}</li>
              <li>{{ "CITIZENS4" | translate }}</li>
            </ul>
          </div>
          </div>
          <div class="column is-half">

          <div class="workshop-type">
            <h3>{{ "FOR_COMPANIES" | translate }}</h3>
            <p>{{ "COMPANIES_TEXT" | translate }}</p>
            <ul>
              <li>{{ "COMPANIES1" | translate }}</li>
              <li>{{ "COMPANIES2" | translate }}</li>
              <li>{{ "COMPANIES3" | translate }}</li>
              <li>{{ "COMPANIES4" | translate }}</li>
            </ul>
            <div class="company-link">
              <a  href="/assets/img/workshop/Reedu_Ferienbetreuung_in_Unternehmen.pdf" target="_blank">
                {{ "COMPANIES_PDF" | translate }}
             </a>
            </div>

          </div>
          </div>
        </div>
       
       
      
        <div class="workshop-type">
          <h3>{{ "FOR_EXAMPLE" | translate }}</h3>
          <ul>
            <li>{{ "EXAMPLE1" | translate }}</li>
            <li>{{ "EXAMPLE2" | translate }}</li>
            <li>{{ "EXAMPLE3" | translate }}</li>
            <li>{{ "EXAMPLE4" | translate }}</li>
          </ul>
          <p>{{ "EXAMPLE_TEXT" | translate }}</p>

        </div>

        <div class="workshop-type workshop-interest">
          <h2>{{ "WORKSHOP_INTEREST" | translate }}</h2>

          <div class="columns is-multiline">
            <div class="column is-half">
              <div>
                {{ "WORKSHOP_INTEREST_TEXT" | translate }} <a href="mailto:workshop@reedu.de">workshop@reedu.de</a>  
                <ul>
                  <li> {{ "WORKSHOP_INFO1" | translate }} </li>
                  <li> {{ "WORKSHOP_INFO2" | translate }} </li>
                  <li> {{ "WORKSHOP_INFO3" | translate }} </li>
                  <li> {{ "WORKSHOP_INFO4" | translate }} </li>
                  <li> {{ "WORKSHOP_INFO5" | translate }} </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="container">
                  <div class="columns is-multiline">
                    <div class="column is-one-third">
                      <img
                        class="profile-img"
                        src="/assets/img/team/verena_new.jpg"
                        alt="image"
                      />  
                    </div>
                    <div class="column">
                      <h1 class="title black">Verena Witte</h1>
                      <h6 class="title is-6 orange">
                        <a href="mailto:v.witte@reedu.de">v.witte@reedu.de</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
