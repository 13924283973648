<div class="tab-wrapper">
  <div class="tab-switcher is-flex is-flex-wrap-wrap">
    <h3
      *ngFor="let tab of tabs; let i = index"
      [class.active]="tab == activeTab"
      class="tab-selector"
      [class.underscore-dark]="tab.underscoreStyle == 'dark'"
      [class.underscore-red]="tab.underscoreStyle == 'red'"
      (click)="changeTab(i)"
    >
      {{ tab.title }}
    </h3>
  </div>
  <div class="tab-content">
    <div class="card">
      <div class="card-content">
        <div class="card-tab-switcher">
          <img
            class="right"
            (click)="nextTab()"
            src="assets/img/logos/logo_outline_red.svg"
          />
          <img
            class="left"
            (click)="previousTab()"
            src="assets/img/logos/logo_outline_red.svg"
          />
        </div>
        <div class="columns">
          <div class="column is-two-thirds">
            <div
              class="is-flex is-align-items-center is-justify-content-space-between"
            >
              <h2 class="tab-title" *ngIf="activeTab.title2">
                <span>{{ activeTab.title }}: </span>{{ activeTab.title2 }}
              </h2>
              <h2 *ngIf="!activeTab.title2">{{ activeTab.title }}</h2>

              <img
                *ngIf="activeTab.logo"
                [src]="activeTab.logo"
                class="tab-logo"
              />
            </div>

            <p class="description-text">{{ activeTab.description }}</p>
            <ul
              style="
                list-style: circle;
                margin-bottom: 2rem;
                list-style-position: inside;
                color: #eb5c37;
              "
              *ngIf="activeTab.list1"
            >
              <li *ngIf="activeTab.list1">{{ activeTab.list1 }}</li>
              <li *ngIf="activeTab.list2">{{ activeTab.list2 }}</li>
              <li *ngIf="activeTab.list3">{{ activeTab.list3 }}</li>
              <li *ngIf="activeTab.list4">{{ activeTab.list4 }}</li>
              <li *ngIf="activeTab.list5">{{ activeTab.list5 }}</li>
              <li *ngIf="activeTab.list6">{{ activeTab.list6 }}</li>
            </ul>
            <p>{{ activeTab.end }}</p>
            <img *ngIf="activeTab.logoBottom" [src]="activeTab.logoBottom" />
            <a *ngIf="activeTab.link" [href]="activeTab.link">
              <button class="re-button">
                <span>{{ activeTab.linkText }}</span>
                <img src="/assets/img/logos/logo_black.svg" />
              </button>
            </a>
            <br />
            <a *ngIf="activeTab.link2" [href]="activeTab.link2">
              <button class="re-button" [ngStyle]="{ 'margin-top': '1rem' }">
                <span>{{ activeTab.link2Text }}</span>
                <img src="/assets/img/logos/logo_black.svg" />
              </button>
            </a>
          </div>
          <div class="column is-full">
            <figure class="image is-3by2">
              <img class="is-tab-image" [src]="activeTab.image" />
            </figure>
            <div class="forschungs-info">
              <div *ngIf="activeTab.forschungsverbund">
                <h3>{{ "FORSCHUNGSVERBUND" | translate }}:</h3>

                <div class="is-flex is-flex-wrap-wrap">
                  <img
                    *ngFor="let logo of activeTab.forschungsverbund"
                    [src]="logo"
                    class="tab-logo"
                  />
                </div>
              </div>
              <div *ngIf="activeTab.foerderung">
                <h3>
                  {{ "FOERDERUNG" | translate }}: {{ activeTab.foerderungName }}
                </h3>
                <div class="is-flex is-flex-wrap-wrap">
                  <img
                    *ngFor="let logo of activeTab.foerderung"
                    [src]="logo"
                    class="tab-logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
