<div class="site sb-white-text">
  <div class="site-title-container">
    <img
      src="./../../../../../assets/img/philosophy/philosophie.jpg"
    />
  </div>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-half">
        <h1 class="pb-6">{{ "PHILOSOPHIE_TITLE" | translate }}</h1>
        <p>
          {{ "PHILOSOPHIE_DESCRIPTION" | translate }}
        </p>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content">
            <p>
              {{ "SPIN_OFF" | translate }}
            </p>
            <br />
            <div class="is-flex is-align-items-center is-flex-wrap-wrap">
              <h2>home of</h2>
              <img
                class="sensebox-logo"
                src="./../../../../../assets/img/logos/sensebox/sensebox_wort_und_logo.svg"
              />
            </div>
            <br />
            <a href="https://sensebox.de">
              <button class="re-button">
                <span>sensebox.de</span>
                <img src="/assets/img/logos/logo_black.svg" />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="stripes-horizontal">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-half">
          <div class="card">
            <div class="card-content spacing">
              <h3 class="underscore">TEAM</h3>
              <h2>{{ "PHILOSOPHIE_WER_WIR_SIND" | translate }}</h2>

              <a [routerLink]="['/team']">
                <button class="re-button">
                  <span>{{ "MEHR_DAZU" | translate }}</span>
                  <img src="/assets/img/logos/logo_black.svg" />
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-content spacing">
              <h3 class="underscore">PORTFOLIO</h3>
              <h2>{{ "PHILOSOPHIE_WAS_WIR_MACHEN" | translate }}</h2>
              <a [routerLink]="['/portfolio']">
                <button class="re-button">
                  <span>{{ "MEHR_DAZU" | translate }}</span>
                  <img src="/assets/img/logos/logo_black.svg" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
